<template>
  <div id="Account">
    <van-nav-bar
      :fixed="true"
      placeholder
      :title="$t('My.PersonalInformation.BankAccounts.BankAccounts')"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 第一个输入模块 -->
    <van-notice-bar color="#333" background="#eee">
      {{ $t('My.PersonalInformation.BankAccounts.YourInformation') }}
    </van-notice-bar>
    <van-field
      colon
      v-model="DataArr.name"
      :label="$t('My.PersonalInformation.BankAccounts.RealName')"
      :placeholder="$t('My.PersonalInformation.BankAccounts.RealNameNote')"
    />
    <van-field
      colon
      v-model="DataArr.phone"
      :label="$t('My.PersonalInformation.BankAccounts.PhoneNo')"
      :placeholder="$t('My.PersonalInformation.BankAccounts.PhoneNoNote')"
    />
    <!-- 第二个输入模块 -->
    <van-notice-bar color="#333" background="#eee">
      {{ $t('My.PersonalInformation.BankAccounts.YourBankCard') }}
    </van-notice-bar>
    <van-field
      label-width="6rem"
      colon
      v-model="DataArr.Account"
      :label="$t('My.PersonalInformation.BankAccounts.BankAccount')"
      :placeholder="$t('My.PersonalInformation.BankAccounts.BankAccountNote')"
    />
    <div class="pull-down">
      <span>{{ $t('My.PersonalInformation.BankAccounts.BankName') }}</span>
      <van-dropdown-menu>
        <van-dropdown-item v-model="DataArr.option" :options="optionData" />
      </van-dropdown-menu>
    </div>
    <van-field
      label-width="6rem"
      colon
      v-model="DataArr.Branch"
      :label="$t('My.PersonalInformation.BankAccounts.BankBranch')"
      :placeholder="$t('My.PersonalInformation.BankAccounts.BankBranchNote')"
    />
    <!-- 第三个输入模块 -->
    <van-notice-bar color="#333" background="#eee">
      {{ $t('My.PersonalInformation.BankAccounts.MoreInformation') }}
    </van-notice-bar>
    <van-field
      colon
      v-model="DataArr.App"
      :label="$t('My.PersonalInformation.BankAccounts.WhatsApp')"
      :placeholder="$t('My.PersonalInformation.BankAccounts.WhatsAppNote')"
    />
    <!-- 底部展示模块 -->
    <div class="button-confirm">
      <div class="text">
        {{ $t('My.PersonalInformation.BankAccounts.Text') }}
      </div>
      <van-button size="normal" type="default" block @click="ButtomEvent()">{{
        $t('My.PersonalInformation.BankAccounts.Settings')
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  DropdownMenu,
  DropdownItem,
  Toast,
} from 'vant'
export default {
  name: 'Account',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Toast.name]: Toast,
  },
  data() {
    return {
      value: 'Aabb2021',
      DataArr: {
        name: '',
        phone: '',
        Account: '',
        option: '',
        Branch: '',
        App: '',
      },
      optionData: [],
    }
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      if (
        this.DataArr.name != '' &&
        this.DataArr.phone != '' &&
        this.DataArr.Account != '' &&
        this.DataArr.option != '' &&
        this.DataArr.Branch != '' &&
        this.DataArr.App != ''
      ) {
        let form = {
          cmd: '200006',
          uid: this.module.UIDEvent(),
          bank: this.DataArr.Branch, // 支付行名称
          bank_card: this.DataArr.Account, // 银行账号
          full_name: this.DataArr.name, // 用户名
          bank_code: this.DataArr.option, // 银行姓名
          bank_phone: this.DataArr.phone, // 手机 账号
          whats_app: this.DataArr.App, // app 账号
        }
        REQUEST_API(form)
          .then((r) => {
            if (r.data.sc == 0) {
              let DataArr = this.module.PersonalEvnet()
              DataArr[1].data.player.bank = this.DataArr.Branch // 支付行名称
              DataArr[1].data.player.bank_card = this.DataArr.Account // 银行账号
              DataArr[1].data.player.full_name = this.DataArr.name // 用户名
              DataArr[1].data.player.bank_code = this.DataArr.option // 银行姓名
              DataArr[1].data.player.bank_phone = this.DataArr.phone // 手机 账号
              DataArr[1].data.player.whats_app = this.DataArr.App // app 账号
              localStorage.setItem('user-info', JSON.stringify(DataArr))
              Toast(this.$t('Hint.success'))
            } else {
              Toast(this.$t('My.PhoneNew'))
            }
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        Toast(this.$t('My.PhoneNew'))
      }
    },
    // 获取银行列表的
    RequestEvent() {
      let form = {
        cmd: '201017',
        uid: this.module.UIDEvent(),
      }
      REQUEST_API(form)
        .then((r) => {
          console.log(r)
          if (r.data.sc == 0) {
            let DataArr = r.data.notifyList[0].data.banks
            DataArr.map((v, i) => {
              let ObjectData = {
                text: v.name,
                value: v.bank_code,
              }
              this.optionData.push(ObjectData)
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  created() {
    ;(this.DataArr.Branch = this.module.PersonalEvnet()[1].data.player.bank), // 支付行名称
      (this.DataArr.Account = this.module.PersonalEvnet()[1].data.player.bank_card), // 银行账号
      (this.DataArr.name = this.module.PersonalEvnet()[1].data.player.full_name), // 用户名
      (this.DataArr.option = this.module.PersonalEvnet()[1].data.player.bank_code), // 银行姓名
      (this.DataArr.phone = this.module.PersonalEvnet()[1].data.player.bank_phone), // 手机 账号
      (this.DataArr.App = this.module.PersonalEvnet()[1].data.player.whats_app), // app 账号
      // 获取银行集合
      this.RequestEvent()
  },
}
</script>

<style lang="less">
#Account {
  width: 100%;
  height: 100%;
  .van-cell {
    .van-cell__title {
      > span {
        font-size: 0.875rem;
      }
    }
  }
  .pull-down {
    width: calc(100% - 32px);
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      font-size: 0.875rem;
      color: #646566;
    }
    .van-dropdown-menu {
      width: 50%;
      .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
  }
  .button-confirm {
    width: 100%;
    margin-top: 1.5625rem;
    > .text {
      color: red;
      width: 90%;
      margin: 0 auto;
      font-size: 0.75rem;
      margin-bottom: 1.5625rem;
    }
    .van-button {
      width: 90%;
      margin: 0 auto;
      border-radius: 0.5rem;
      background-color: #2f3848;
      color: #ffffff;
    }
  }
}
</style>